<template>
  <TopChartsSidebarFilter
    :need-currencies-filter="false"

    :selected-date-filter="selectedDateFilter"
    :selected-gateways-list="selectedGatewaysList"

    @apply-date-changes="applyDateFilter"
    @apply-gateways-changes="applyGatewaysFilter"

    @discard-gateways-changes="discardGatewaysFilter"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import TopChartsSidebarFilter from '@/views/TopChartsLayout/TopChartsSidebarFilter';

export default {
  name: 'TopChartsCryptosFilter',

  components: {
    TopChartsSidebarFilter,
  },

  computed: {
    ...mapGetters('topCryptosStore', {
      selectedDateFilter: 'selectedDateFilter',
      selectedGatewaysList: 'selectedGatewaysList',
    }),
  },

  methods: {
    ...mapActions('topCryptosStore', {
      setDateFilter: 'setDateFilter',
      setGatewaysFilter: 'setGatewaysFilter',

      loadTopCryptos: 'loadTopCryptos',
    }),

    applyDateFilter({ from, to }) {
      this.setDateFilter({
        from, to,
      })
        .then(this.loadTopCryptos());
    },

    applyGatewaysFilter(changes) {
      this.setGatewaysFilter(changes)
        .then(this.loadTopCryptos());
    },

    discardGatewaysFilter() {
      this.setGatewaysFilter([])
        .then(this.loadTopCryptos());
    },
  },
};
</script>
